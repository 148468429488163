import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const initialState = {};
const storeName = 'cache';
export const useCache = create(
  devtools((set) => ({ ...initialState }), {
    name: `${process.env.NX_APP}`,
    store: storeName,
  }),
);

export const setCache = (key, value) => {
  useCache.setState({ [key]: value }, false, {
    type: 'setCache',
  });
};
export const addCache = (key, value, cacheLength = 20) => {
  const cache = useCache.getState()[key];
  setCache(key, [value, ...(cache?.slice(0, cacheLength) || [])]);
};

export const clearCacheStore = () =>
  useCache.setState({ ...initialState }, false, {
    type: 'clearCacheStore',
  });
