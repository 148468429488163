import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { migrateReduxSessionStorageFilterValue, sessionStorageMF } from '../../utils';
import { setFilterValue } from '../filterValue';

const initialState = {
  display: 'Amenities',
  name: 'infusionCenterAmenities',
  type: 'multiSelect',
  selected: [],
  selectedVal: [],
  options: [
    {
      display: 'Beverages/Snacks',
      value: 'Beverages/Snacks',
    },
    { display: 'Reclining Chairs', value: 'Reclining Chairs' },
    { display: 'Parking', value: 'Parking' },
    { display: 'Power Outlets', value: 'Power Outlets' },
    { display: 'TV', value: 'TV' },
    { display: 'Waiting Area for Caregivers', value: 'Waiting Area for Caregivers' },
    { display: 'Family Rooms', value: 'Family Rooms' },
    { display: 'Blankets/Pillows', value: 'Blankets/Pillows' },
    { display: 'Handicap Accessible', value: 'Handicap Accessible' },
    { display: 'Transportation Services', value: 'Transportation' },
    { display: 'Wi-Fi', value: 'WiFi' },
    { display: 'iPads', value: 'iPads' },
    { display: 'Private Infusion Rooms', value: 'Private Infusion Rooms' },
  ],
};

const storeName = 'infusionCenterAmenities';
export const useInfusionCenterAmenitiesFilter = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
        reset: () => set({ ...initialState }, false, { type: 'reset' }),
        getInitialState: () => ({ ...initialState }),
        setFilterValue: (v) => updateInfusionCenterAmenitiesOption(v),
      }),
      {
        name: `${process.env.NX_APP}-${storeName}`,
        version: 0,
        storage: createJSONStorage(() => sessionStorageMF),
        onRehydrateStorage: () => (state) => {
          migrateReduxSessionStorageFilterValue({ state, key: 'infusionCenterAmenities' });
        },
      },
    ),
    {
      name: `${process.env.NX_APP}`,
      store: storeName,
    },
  ),
);

export const updateInfusionCenterAmenitiesOption = (option) => {
  setFilterValue(useInfusionCenterAmenitiesFilter, option);
};

export const clearInfusionCenterAmenitiesOption = () =>
  useInfusionCenterAmenitiesFilter.setState({ ...initialState }, false, {
    type: 'clearInfusionCenterAmenitiesOption',
  });
