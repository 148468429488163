import { gql } from '@apollo/client';

export const GQL_GET_CENTER_AUTOCOMPLETE = gql`
  query AutoComplete($searchString: String!) {
    autoComplete(searchString: $searchString) {
      center {
        internalId
        centerName
        city
        state
        zip
      }
    }
  }
`;

export const GQL_GET_UPDATES_AUTOCOMPLETE = gql`
  query AutoComplete($searchString: String!, $updateSearch: Boolean) {
    autoComplete(searchString: $searchString, updateSearch: $updateSearch) {
      updatesCenters {
        internalId
        centerName
        city
        state
        zip
      }
    }
  }
`;

export const GQL_GET_DRUGS = gql`
  query Drugs {
    drugs {
      count
      rows {
        id
        name
        display
        value
      }
    }
  }
`;

export const GQL_GET_CENTERS = gql`
  query Centers(
    $page: Int
    $size: Int
    $offset: Int
    $limit: Int
    $lat: String
    $lon: String
    $dist: Int
    $service: [String]
    $medication: [String]
    $amenity: [String]
    $insurance: [String]
    $type: [String]
    $day: [String]
    $workingHr: [TimeSlot]
    $radius: StringInt
    $stateProvinceCode: String
    $sort: String
  ) {
    centers(
      page: $page
      size: $size
      offset: $offset
      limit: $limit
      lat: $lat
      lon: $lon
      dist: $dist
      service: $service
      medication: $medication
      amenity: $amenity
      insurance: $insurance
      type: $type
      day: $day
      workingHr: $workingHr
      radius: $radius
      stateProvinceCode: $stateProvinceCode
      sort: $sort
    ) {
      count
      rows {
        centerName
        fax
        hours
        internalId
        location {
          address1
          address2
          city
          latitude
          longitude
          state
          zip
        }
        medications
        phone
        type
        distance
        website
      }
    }
  }
`;

export const GQL_GET_CENTER = gql`
  query Center($internalId: ID!) {
    center(internalId: $internalId) {
      internalId
    npi
    centerName
    alternateName
    parentCompany
    location {
      address1
      address2
      city
      zip
      state
      longitude
      latitude
    }
    phone
    phoneExt
    fax
    email
    website
    medications
    insurance
    amenities
    services
    types
    hours {
      sunday
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      closed
    }
    contact {
      firstName
      lastName
      email
    }
    open
    checked
    }
  }
`;

export const GQL_GET_PENDING_UPDATES = gql`
  query PendingUpdates($internalId: ID!) {
    pendingUpdates(internalId: $internalId) {
      internalId
      npi
      centerName
      alternateName
      parentCompany
      location {
        address1
        address2
        city
        zip
        state
        longitude
        latitude
      }
      phone
      phoneExt
      fax
      email
      website
      medications
      insurance
      amenities
      services
      types
      hours {
        sunday
        monday
        tuesday
        wednesday
        thursday
        friday
      saturday
      closed
    }

    hoursOfOperation {
      sunday {
              allDay
                closed
                open
                close
              }
        monday {
                allDay
                closed
                open
                close
              }
        tuesday {
                allDay
                closed
                open
                close
              }
        wednesday {
                allDay
                closed
                open
                close
              }
        thursday {
                allDay
                closed
                open
                close
              }
        friday {
                allDay
                closed
                open
                close
              }
        saturday {
                allDay
                closed
                open
                close
              }
      }
      contact {
        firstName
        lastName
        email
      }
      open
      checked
      recommendations {
        field
        name
      }
    }
  }
`;
