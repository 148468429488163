import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { migrateReduxSessionStorageFilterValue, sessionStorageMF } from '../../utils';
import { setFilterValue } from '../filterValue';

const initialState = {
  display: 'Services',
  name: 'infusionCenterServices',
  type: 'multiSelect',
  selected: [],
  selectedVal: [],
  options: [
    {
      display: 'Appointment Scheduling',
      value: 'Appointment Scheduling',
    },
    { display: 'Insurance Verification', value: 'Insurance Verification' },
    { display: 'Referral Coordination', value: 'Referral Coordination' },
    { display: 'Financial Counseling', value: 'Financial Counseling' },
    {
      display: 'Interpreter Services',
      value: 'Interpreter Services',
    },
  ],
};

const storeName = 'infusionCenterServices';
export const useInfusionCenterServicesFilter = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
        reset: () => set({ ...initialState }, false, { type: 'reset' }),
        getInitialState: () => ({ ...initialState }),
        setFilterValue: (v) => updateInfusionCenterServicesOption(v),
      }),
      {
        name: `${process.env.NX_APP}-${storeName}`,
        version: 0,
        storage: createJSONStorage(() => sessionStorageMF),
        onRehydrateStorage: () => (state) => {
          migrateReduxSessionStorageFilterValue({ state, key: 'infusionCenterServices' });
        },
      },
    ),
    {
      name: `${process.env.NX_APP}`,
      store: storeName,
    },
  ),
);

export const updateInfusionCenterServicesOption = (option) => {
  setFilterValue(useInfusionCenterServicesFilter, option);
};

export const clearInfusionCenterServicesOption = () =>
  useInfusionCenterServicesFilter.setState({ ...initialState }, false, {
    type: 'clearInfusionCenterServicesOption',
  });
