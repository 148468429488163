import { useEffect } from 'react';
import { v5 as uuidv5 } from 'uuid';
import { addCache, useCache } from '@medifind/zustand';

const expensivesInProgress = {};

const compareArray = (a, b) => {
  return a.findIndex((x, i) => x !== b[i]) < 0;
};

export const reCache = (key, expensive, deps) => {
  const cacheValue = useCache.getState()[key]?.find((x) => compareArray(x.deps, deps));
  if (!cacheValue && expensive) {
    return getExpensivePromise(key, expensive, deps, true);
  }
  return cacheValue ? cacheValue.value : null;
};

export const reCachePromise = (key, expensive, deps) => {
  const cacheValue = useCache.getState()[key]?.find((x) => compareArray(x.deps, deps));
  if (!cacheValue) {
    return getExpensivePromise(key, expensive, deps, true);
  }
  return cacheValue.value;
};

export const useReCache = (key, expensive, deps, nonNullDeps) => {
  const notNull = (nonNullDeps || []).findIndex((x) => x == null) === -1;
  const cache = useCache((state) => state[key] || []);

  const cacheValue = cache.find((x) => compareArray(x.deps, deps));

  useEffect(() => {
    if (!cacheValue && expensive && notNull) {
      getExpensivePromise(key, expensive, deps);
    }
  }, [...deps, cacheValue, notNull]); // Note: Removed expensive from here, hopefully doesnt cause issues, this should be closer to how a hook works

  return cacheValue ? cacheValue.value : null;
};

export const useReCachePromise = (key, expensive, promiseResponse, deps, nonNullDeps) => {
  const notNull = (nonNullDeps || []).findIndex((x) => x == null) === -1;

  useEffect(() => {
    if (expensive && notNull) {
      promiseResponse(reCachePromise(key, expensive, deps));
    }
  }, [...deps, notNull]); // Note: Removed expensive from here, hopefully doesnt cause issues, this should be closer to how a hook works
};

// Returns a promise that already 'in progress'. Added due to competing components trying to run the same requests at the same time.
function getExpensivePromise(key, expensive, deps, cachePromiseNotResult) {
  const depsHash = uuidv5(JSON.stringify({ key, deps }), '00000000-0000-0000-0000-000000000000');
  let expensivePromise = expensivesInProgress[depsHash];
  if (expensivePromise == null) {
    expensivePromise = expensive();
    expensivePromise.catch(() => {});
    if (cachePromiseNotResult) {
      addCache(key, { deps, value: expensivePromise });
    } else {
      expensivePromise = expensivePromise.then((x) => {
        addCache(key, { deps, value: x });
      });
    }
    expensivePromise = expensivePromise.finally(() => {
      setTimeout(() => delete expensivesInProgress[depsHash], 1000);
    });
    expensivesInProgress[depsHash] = expensivePromise;
  }

  return expensivePromise;
}
