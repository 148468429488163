import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, concat } from '@apollo/client';
import PropTypes from 'prop-types';

const httpLink = new HttpLink({
  uri: process.env.IC_API_HOST,
});

const authMiddleware = (token) => new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...(process.env.IC_X_API_KEY ? { 'X-API-KEY': process.env.IC_X_API_KEY } : {}),
      authorization: token ? `Bearer ${token}` : '',
    },
  }));
  return forward(operation);
});

const client = (token) => new ApolloClient({
  link: concat(authMiddleware(token), httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

const ApolloWrapper = ({ token, children }) => <ApolloProvider client={client(token)}>{children}</ApolloProvider>;

ApolloWrapper.propTypes = {
  children: PropTypes.any,
};

export { ApolloWrapper as default, ApolloWrapper };
