import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { migrateReduxSessionStorageFilterValue, sessionStorageMF } from '../../utils';
import { setFilterValue } from '../filterValue';

const initialState = {
  display: 'Insurances',
  name: 'infusionCenterInsurances',
  type: 'multiSelect',
  selected: [],
  selectedVal: [],
  options: [
    {
      display: 'Most Commercial Insurance Plans',
      value: 'Most Commercial Insurance Plans',
    },
    { display: 'Medicaid', value: 'Medicaid' },
    { display: 'Medicare', value: 'Medicare' },
    { display: 'TRICARE', value: 'TRICARE' },
    {
      display: 'Patient Assistance Program',
      value: 'Patient Assistance Program',
    },
    { display: 'Clinical Trials', value: 'Clinical Trials' },
  ],
};

const storeName = 'infusionCenterInsurances';
export const useInfusionCenterInsurancesFilter = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
        reset: () => set({ ...initialState }, false, { type: 'reset' }),
        getInitialState: () => ({ ...initialState }),
        setFilterValue: (v) => updateInfusionCenterInsurancesOption(v),
      }),
      {
        name: `${process.env.NX_APP}-${storeName}`,
        version: 0,
        storage: createJSONStorage(() => sessionStorageMF),
        onRehydrateStorage: () => (state) => {
          migrateReduxSessionStorageFilterValue({ state, key: 'infusionCenterInsurances' });
        },
      },
    ),
    {
      name: `${process.env.NX_APP}`,
      store: storeName,
    },
  ),
);

export const updateInfusionCenterInsurancesOption = (option) => {
  setFilterValue(useInfusionCenterInsurancesFilter, option);
};

export const clearInfusionCenterInsurancesOption = () =>
  useInfusionCenterInsurancesFilter.setState({ ...initialState }, false, {
    type: 'clearInfusionCenterInsurancesOption',
  });
